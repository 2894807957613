/**
 * Use this file to conditionally override styles for devices.
 * If you adjust the media queries in the theme settings you should
 * adjust them here so they match.
 */

#content-wrapper,
#footer-wrapper .container,
#three33-content-wrapper .container,
#tertiary-content-wrapper .container,
#two50-content-wrapper .container,
#bottom-banner-content-wrapper {
	margin-left: auto;
	margin-right: auto;
	max-width: 1200px;
	width: 100%;

	// @media only screen and (min-width:1024px) {
	// 	padding-left: 20px;
 	// 	padding-right: 20px;
	// }
}

/* Smartphone portrait */
@media only screen and (max-width:320px) {
	/*#secondary-content-wrapper, #content-wrapper, #header-wrapper .container, #nav-wrapper .container, #leaderboard-wrapper .container, #footer-wrapper .container, #three33-content-wrapper .container, #tertiary-content-wrapper .container, #two50-content-wrapper .container, #bottom-banner-content-wrapper {width: 310px;}*/
	html, body, #page-wrapper {width:100%; overflow-x:hidden;}
	#RRcredit {top: 3px;}
	/*#RRcredit .graphic {float: right;}*/
	.nav li, .nav .menu li {display: block; width: 100%; text-align: center; border-bottom: solid 1px #0071BC;}
	#block-block-23 {display: block; float: none; text-align: center; width: 100%;}
	/*.nav li.last, .nav .menu li.last {border-bottom: none;}*/
	/*#tertiary-content-wrapper ul.menu li {display: block; width: 100%; border-right: none; border-bottom: solid 1px #fff;}*/
	#tertiary-content-wrapper ul.menu li.last {border-bottom: none;}
	/*li#menu-309-1 ul, li#menu-309-1 ul li {display: none !important;}*/
	/*.jcarousel-skin-default .jcarousel-prev-horizontal {left: -30px;}*/
	/*.jcarousel-skin-default .jcarousel-next-horizontal {right: -30px;}*/
	/*.jcarousel-skin-default .jcarousel-item {width: 100px; height: 100px;}*/
	/*.jcarousel-skin-default .jcarousel-container-horizontal, .jcarousel-skin-default .jcarousel-clip-horizontal {width: 250px; height: 102px;}*/
	#bottom-banner-content-wrapper h4#moreRR {font-size: 1em; font-weight: bold; margin-top: 0;}
	.region-three-33-first .block-inner, .region-three-33-second .block-inner {border-right: none; border-bottom: dotted 1px #0071BC; margin-right: 0;}
	.two-50 {padding: 1em;}
	/*.two-50 .one {text-align: center;}*/
	.two-50 .region-two-50-first .block-inner {border-bottom: dotted 1px #0071BC; padding-bottom: 1em; border-right: none; padding-right: 0;}
	#block-block-12 ul li {display: inline; margin-bottom: .5em;}
	#block-block-12 ul li#followus {display: block; clear: both;}
	#block-block-1 {clear: both; display: inline; width: 100%;}
}

/* Smartphone landscape */
@media only screen and (min-width:321px) and (max-width:480px) {
	html, body, #page-wrapper {width:100%; overflow-x:hidden;}
	/*#secondary-content-wrapper, #content-wrapper, #header-wrapper .container, #nav-wrapper .container, #leaderboard-wrapper .container, #footer-wrapper .container, #three33-content-wrapper .container, #tertiary-content-wrapper .container, #two50-content-wrapper .container, #bottom-banner-content-wrapper {width: 310px;}*/
	#RRcredit {top: 3px;}
	.nav li, .nav .menu li {display: block; width: 100%; text-align: center; border-bottom: solid 1px #0071BC;}
	#block-block-23 {display: block; float: none; text-align: center; width: 100%;}
	.nav li.last, .nav .menu li.last {border-bottom: none;}
	#tertiary-content-wrapper ul.menu li {display: block; width: 100%; border-right: none; border-bottom: solid 1px #fff;}
	#tertiary-content-wrapper ul.menu li.last {border-bottom: none;}
	li#menu-309-1 ul, li#menu-309-1 ul li {display: none !important;}
	.two-50 {padding: 1em;}
	#block-block-1 {clear: both; display: inline; width: 100%;}
	/*.jcarousel-skin-default .jcarousel-prev-horizontal {left: -30px;}*/
	/*.jcarousel-skin-default .jcarousel-next-horizontal {right: -30px;}*/
	/*.jcarousel-skin-default .jcarousel-item {width: 100px; height: 100px;}*/
	/*.jcarousel-skin-default .jcarousel-container-horizontal, .jcarousel-skin-default .jcarousel-clip-horizontal {width: 300px; height: 102px;}*/
	.two-50 .region-two-50-first .block-inner {border-bottom: dotted 1px #0071BC; padding-bottom: 1em; border-right: none; padding-right: 0;}
	.region-three-33-first .block-inner, .region-three-33-second .block-inner {border-right: none; border-bottom: dotted 1px #0071BC; margin-right: 0;}
}

/* Tablet portrait */
@media only screen and (min-width:481px) and (max-width:768px) {
	/*#secondary-content-wrapper, #content-wrapper, #header-wrapper .container, #nav-wrapper .container, #leaderboard-wrapper .container, #footer-wrapper .container, #three33-content-wrapper .container, #tertiary-content-wrapper .container, #two50-content-wrapper .container, #bottom-banner-content-wrapper {width: 470px;}*/
	html, body, #page-wrapper {
		width: 100%;
		overflow-x: hidden;
	}
	#block-block-2 {
		/*float: none;*/
	}
	/* weta logo */
	#weta, #block-menu-menu-mini {
		margin: 0;
	}
	#block-menu-menu-mini img {
		position: relative;
		top: 15px;
	}
	#RRcredit {
		top: 3px;
		margin-top: 1em;
	}
	.two-50 {
		padding: 1em;
	}
	#block-block-1 {
		float: left;
		display: inline;
		width: 400px;
	}
	/*.jcarousel-skin-default .jcarousel-prev-horizontal {*/
		/*left: -30px;*/
	/*}*/
	/*.jcarousel-skin-default .jcarousel-next-horizontal {*/
		/*right: -30px;*/
	/*}*/
	/*.jcarousel-skin-default .jcarousel-item {*/
		/*width: 100px;*/
		/*height: 100px;*/
	/*}*/
	/*.jcarousel-skin-default .jcarousel-container-horizontal, .jcarousel-skin-default .jcarousel-clip-horizontal {*/
		/*width: 400px;*/
		/*height: 102px;*/
	/*}*/
	/*#views_slideshow_cycle_main_slideshow-block, #views_slideshow_cycle_main_slideshow-block_1 {*/
		/*width: 737px;*/
		/*height: 290px;*/
		/*margin: auto;*/
		/*padding-top: .5em*/
	/*}*/
	.views-field.views-field-field-mini-image, .views-field.views-field-title, .views-slideshow-cycle-main-frame-row-item {
		background-color: #0e76bc;
	}
	.view-related-blogs .views-field.views-field-title {
		background: none !important;
		background-color: white !important;
	}
	.views-slideshow-controls-top {
		display: none;
	}
	/*.views-field.views-field-field-mini-image, .views-field.views-field-title, .views-slideshow-cycle-main-frame-row-item {background-color: #0e76bc;}*/
	.views-field-field-mini-image {
		padding: .3em;
		margin-left: 1em
	}
}

/* 960 width exception*/
@media only screen and (min-width:769px) and (max-width:959px) {
	/*#secondary-content-wrapper, #content-wrapper, #header-wrapper .container, #nav-wrapper .container, #leaderboard-wrapper .container, #footer-wrapper .container, #three33-content-wrapper .container, #tertiary-content-wrapper .container, #two50-content-wrapper .container, #bottom-banner-content-wrapper {width: 930px;}*/
	.two-50 .region .one {float: left; width: 125px; margin-right: 1em;}
	.two-50 .region .two {float: left; width: 190px;}
	/*#block-views-slideshow-block {display: none;}*/
	#views_slideshow_cycle_main_slideshow-block, #views_slideshow_cycle_main_slideshow-block_1 {width: 737px; height: 290px; margin: auto; padding-top: .5em}
	.views-slideshow-controls-top {display: none;}
	.views-field.views-field-field-mini-image, .views-field.views-field-title, .views-slideshow-cycle-main-frame-row-item {background-color: #0e76bc;}
	.view-related-blogs .views-field.views-field-title {
		background: none !important;
		background-color: white !important;
	}
	.views-field-field-mini-image {padding: .5em; margin-left: 1em}
	#block-views-slideshow-block .views-field-title {padding: .7em;}
	/*#views_slideshow_cycle_main_slideshow-block {height: 245px; width: 100%}
	#views_slideshow_cycle_div_slideshow-block_0 {margin: auto}
	#block-views-slideshow-block .views-field.views-field-title {color: #000}*/
	/*.views-slideshow-pager-field-item {height: 30px;}*/
	/*#block-block-13 {width: 650px; display: inline; float: left; margin: 0; padding: 0;}
	#block-block-23 {width: 220px; display: inline; float: right; margin: 2em 0; padding: 0;}*/ /*search bar*/
	#logo {margin-bottom: 0}

}
@media only screen and (min-width:960px) and (max-width:1024px) {
	.two-50 .region .one {float: left; width: 125px; margin-right: 1em;}
	.two-50 .region .two {float: left; width: 300px;}
	.two-50 .region .two a {font-size: 90%}
	.views-slideshow-controls-top {float: left; width: 220px;}
	#views_slideshow_cycle_main_slideshow-block, #views_slideshow_cycle_main_slideshow-block_1 {float: left; height: 245px; width: 737px;}
	.views-slideshow-pager-field-item {height: 81px;}
	#block-views-slideshow-block-1 .views-slideshow-pager-field-item {height: 61px;}
	#block-views-slideshow-block-1 #views_slideshow_pager_field_item_top_slideshow-block_1_0 {height: 62px;}
	.views-field.views-field-field-mini-image, .views-field.views-field-title {display: none;}
	.view-related-blogs .views-field.views-field-title {
		display: block !important;
	}
	#block-block-13 {width: 710px; display: inline; float: left; margin: 0; padding: 0;}
	#block-block-23 {width: 220px; display: inline; float: right; margin: 1.5em 0 0 0; padding: 0;} /*search bar*/
	#logo {margin: .8em 0 0 0;}
}

/* Tablet landscape */
@media only screen and (min-width:769px) and (max-width:1024px) {
	/*#secondary-content-wrapper, #content-wrapper, #header-wrapper .container, #nav-wrapper .container, #leaderboard-wrapper .container, #footer-wrapper .container, #three33-content-wrapper .container, #tertiary-content-wrapper .container, #two50-content-wrapper .container, #bottom-banner-content-wrapper {width: 930px;}*/
	html, body, #page-wrapper {width:100%; overflow-x:hidden;}
	.jcarousel-skin-default .jcarousel-prev-horizontal {left: -30px;}
	.jcarousel-skin-default .jcarousel-next-horizontal {right: -30px;}
	.jcarousel-skin-default .jcarousel-item {width: 200px; height: 200px;}
	.jcarousel-skin-default .jcarousel-container-horizontal, .jcarousel-skin-default .jcarousel-clip-horizontal {width: 680px; height: 202px;}
	/*#RRcredit .credit {width: 260px; position: relative; top: 10px;}
	#RRcredit .graphic {width: 100px;}
	#block-block-2 {width: 100px;}*/ /* weta logo */
	#RRcredit {top: 6px; left: 4em}
	#RRcredit .credit {width: 300px; position: relative; top: 10px;}
	#RRcredit .graphic {width: 100px;}
	#block-block-2 {width: 100px; right: 2em; top: 5px;} /* weta logo */
	#block-menu-menu-mini {display: inline; width: 380px; float: left;}
	.fRight {width: calc(60% - 2em);}
	.view-booklist .views-row .book_cover {width: 100px;}
	.article-type-page .field-name-field-image {width: 37%;}
	.article-type-page .field-name-field-intro {width: calc(60% - 2em);}
	#three33-content-wrapper .region-three-33-first .block-inner {margin-left: 22px;}
	/*#logo, */#slogans {margin: 10px 0;}
	/*#logo {margin: .8em 0 1em 0;}*/
	.region-three-25-25-50-second {left: -125px;}
	#nav-wrapper {box-shadow: 0 2px 3px;}
	/*#block-views-related-block, #block-views-related-videos-block-2, #block-views-related-videos-block-1 {box-shadow: 1px 1px 7px #737373;}*/
	/*#block-views-related-dc-activities-block {box-shadow: 1px 1px 7px #737373;}*/
	/*#block-views-reading-tips-block, #block-views-reading-tips-block-1 {box-shadow: 1px 1px 7px #737373;}*/
	/*#main-content {margin-right: 2em;}*/
	ul#superfish-1 li#menu-698-1 {background: url("../images/3stars_w.png") no-repeat 1em center; padding-left: 75px !important;}
	.two-50 {padding: 1em;}
	#block-block-1 {float: left; display: inline; width: 450px;}
	#block-menu-menu-mini ul.menu li.last a img {position: relative; top: 1.5em}
	#block-menu-menu-mini {margin-top: 0;}
	/*.views-slideshow-controls-top {float: left; width: 220px;}
	#views_slideshow_cycle_main_slideshow-block {float: left; height: 245px; width: 737px;}*/
	/*.views-slideshow-pager-field-item {height: 81px;}*/
}

/* Standard layout */
@media only screen and (min-width:1025px) {
	.view-dc-for-kids .view-header,
	.booklist-left {
		.left {
			width: 40%;
			float: left;
			padding-right: 30px;
		}
	}
	.view-dc-for-kids .view-header .intro,
	.booklist-left .fRight,
	.article-type-page .field-name-field-intro {
		width: calc(60% - 2em);
	}
	#RRcredit {top: 7px; left: 4em}
	#RRcredit .credit {width: 300px; position: relative; top: 10px;}
	#RRcredit .graphic {width: 100px;}
	#block-block-2 {width: 100px; right: 2em} /* weta logo */
	#block-menu-menu-mini {display: inline; width: 400px; float: left; /*margin-top: -1em;*/}
	#block-menu-menu-mini ul.menu li.last a img {position: relative; top: 1.5em}
	#block-block-1 {float: left; display: inline; width: 450px;}
	.jcarousel-skin-default .jcarousel-item {width: 220px; height: 220px;}
	.jcarousel-skin-default .jcarousel-container-horizontal, .jcarousel-skin-default .jcarousel-clip-horizontal {width: 920px; height: 222px;}
	.caroHov span {width: 100%;}
	.view-id-flb_featured_graphic {width: 150px;}
	.view-id-related_literacy_bags .left {width: 135px;}
	.view-id-related_family_literacy_bags .view-content .item-list, .view-id-related_literacy_bags .view-content {width: 300px;}
	.article-type-page .field-name-field-image {width: 37%;}
	// .article-type-page .field-name-field-intro {width: 250px;}
	.jcarousel-skin-default .jcarousel-next-horizontal, .jcarousel-skin-default .jcarousel-prev-horizontal {top: 110px;}
	.jcarousel-skin-default .jcarousel-prev-horizontal {left: -30px;}
	.jcarousel-skin-default .jcarousel-next-horizontal {right: -30px;}
	#three33-content-wrapper .region-three-33-first .block-inner {margin-left: 22px;}
	#logo, #slogans {margin: 10px 0;}
	#logo {margin: 0;}
	.region-three-25-25-50-second {left: -125px;}
	.two-50 .region .one {float: left; width: 125px; margin-right: 1em;}
	.two-50 .region .two {float: left; width: 310px;}
	#nav-wrapper {box-shadow: 0 2px 3px;}
	/*#views_slideshow_cycle_main_slideshow-block {box-shadow: 1px 4px 9px -6px inset}
	.views-slideshow-cycle-main-frame-row, .views-slideshow-cycle-main-frame {z-index: -999}*/
	/*#views_slideshow_cycle_main_slideshow-block img {z-index: -999; position: relative;}*/
	/*#block-views-reading-tips-block, #block-views-reading-tips-block-1, #block-views-related-block, #block-views-related-videos-block-2, #block-views-related-videos-block-1, #block-views-dc-for-kids-block-1, #block-views-related-dc-activities-block {box-shadow: 1px 1px 7px #737373;}*/
	#main-content {margin-right: 2em;}
	ul#superfish-1 li#menu-698-1 {background: url("../images/3stars_w.png") no-repeat 1em center; padding-left: 75px !important;}
	.not-front #block-system-main .block-inner {margin-left: 0;}
	#block-print-print-links .block-inner {margin: 0;}
	#block-block-20 .block-inner {margin: 0;}
	#block-block-13 {width: 710px; display: inline; float: left; margin: 0; padding: 0;}
	#block-block-23 {width: 220px; display: inline; float: right; margin: .75em 0; padding: 0;} /*search bar*/
	nav#block-superfish-1 {margin-left: 10px;}
	.views-slideshow-controls-top {float: left; width: 220px;}
	#views_slideshow_cycle_main_slideshow-block, #views_slideshow_cycle_main_slideshow-block_1 {float: left; height: 245px; width: 737px;}
	.views-slideshow-pager-field-item {height: 81px;}
	#block-views-slideshow-block-1 .views-slideshow-pager-field-item {height: 61px;}
	#block-views-slideshow-block-1 #views_slideshow_pager_field_item_top_slideshow-block_1_0 {height: 62px;}
	.views-field.views-field-field-mini-image {display: none;}
	.views_slideshow_slide .views-field-title {display: none;}
}


/* ----------- iPhone 5 and 5S ----------- */

/* Portrait and Landscape */
@media only screen
and (min-device-width: 320px)
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2) {

	/* Top Menu */
	li#menu-309-1 ul, li#menu-309-1 ul li {
		display: none !important;
	}
	.nav li, .nav .menu li {
		display: block;
		width: 100%;
		text-align: center;
		border-bottom: solid 1px #0071BC;
	}
	a.sf-depth-1 {
		font-size: 16px;
		text-align: left;
	}

	/* Slider */
	#block-views-slideshow-block, #block-views-slideshow-block-1 {display: none;}

	/* Masthead */
	#block-block-1 > div { float: left;}

	/* Share Print Buttons */
	div#content #block-swab-share-print-swab-share-print {
		float: right;
		margin-top: -10px;
		margin-bottom: 5px;
	}

	/* Book Text */
	.book_blurb {
		float: none !important;
	}

	/* Menu Text */
	a.sf-depth-1 {
		font-size: 16px;
		text-align: left;
	}

	/* Bottom Menu */
	#tertiary-content-wrapper ul.menu li a {
		font-size: 16px;
	}
	#tertiary-content-wrapper ul.menu li {
		padding-top: 10px;
		padding-bottom: 10px;
		width: 100%;
		display: block;
		border-bottom: solid 1px #0071BC;
	}
	#tertiary-content-wrapper ul.menu li.last {
		width: 100%;
		display: block;
		border-bottom: 0;
	}
}

/* Portrait */
@media only screen
and (min-device-width: 320px)
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) {
	#RRcredit > div.graphic { display: none;}
	#weta { margin-top: 5px; margin-bottom: 0;}
	#RRcredit {
		font-size: 80%;
	}
	.box {
		width: 45%;
		margin-left: 5px !important;
		margin-right: 5px !important;
	}
	// .box span {
	// 	height: 40px;
	// }
	.jcarousel-skin-default .jcarousel-prev-horizontal {left: -30px;}
	.jcarousel-skin-default .jcarousel-next-horizontal {right: -30px;}
	.jcarousel-skin-default .jcarousel-item {width: 130px; height: 130px;}
	.jcarousel-skin-default .jcarousel-container-horizontal, .jcarousel-skin-default .jcarousel-clip-horizontal {width: 280px; height: 132px;}
	ul#share {
		font-size: 70%;
	}
	#bottom-banner-content-wrapper {
		background: none;
	}
	#bottom-banner-content-wrapper div.block-content {
		background-color: #ed1c24;
		margin-left: -25px;
		padding-left: 25px;
		margin-top: -15px;
		padding-top: 15px;
	}
}

/* Landscape */
@media only screen
and (min-device-width: 320px)
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: landscape) {
	.box {
		width: 31%;
		margin-left: 5px !important;
		margin-right: 5px !important;
	}
	.contentbox .row {
		clear: none;
	}
	#weta { margin-top: 10px; margin-bottom: 10px;}
	.jcarousel-skin-default .jcarousel-prev-horizontal {left: -30px;}
	.jcarousel-skin-default .jcarousel-next-horizontal {right: -30px;}
	.jcarousel-skin-default .jcarousel-item {width: 165px; height: 165px;}
	.jcarousel-skin-default .jcarousel-container-horizontal, .jcarousel-skin-default .jcarousel-clip-horizontal {width: 520px; height: 167px;}
}

/* ----------- iPhone 6 ----------- */

/* Portrait and Landscape */
@media only screen
and (min-device-width: 375px)
and (max-device-width: 667px)
and (-webkit-min-device-pixel-ratio: 2) {

	/* Top Menu */
	li#menu-309-1 ul, li#menu-309-1 ul li {
		display: none !important;
	}
	.nav li, .nav .menu li {
		display: block;
		width: 100%;
		text-align: center;
		border-bottom: solid 1px #0071BC;
	}
	a.sf-depth-1 {
		font-size: 16px;
		text-align: left;
	}

	/* Slider */
	#block-views-slideshow-block, #block-views-slideshow-block-1 {display: none;}

	/* Masthead */
	#block-block-1 > div { float: left;}

	/* Share Print Buttons */
	div#content #block-swab-share-print-swab-share-print {
		float: right;
		margin-top: -10px;
		margin-bottom: 5px;
	}

	/* Book Text */
	.book_blurb {
		float: none !important;
	}

	/* Menu Text */
	a.sf-depth-1 {
		font-size: 16px;
		text-align: left;
	}

	/* Bottom Menu */
	#tertiary-content-wrapper ul.menu li a {
		font-size: 16px;
	}
	#tertiary-content-wrapper ul.menu li {
		padding-top: 10px;
		padding-bottom: 10px;
		width: 100%;
		display: block;
		border-bottom: solid 1px #0071BC;
	}
	#tertiary-content-wrapper ul.menu li.last {
		width: 100%;
		display: block;
		border-bottom: 0;
	}
}

/* Portrait */
@media only screen
and (min-device-width: 375px)
and (max-device-width: 667px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) {
	#RRcredit > div.graphic { display: none;}
	#weta { margin-top: 5px; margin-bottom: 0;}
	#RRcredit {
		font-size: 80%;
	}
	.box {
		width: 46%;
		margin-left: 5px !important;
		margin-right: 5px !important;
	}
	// .box span {
	// 	height: 30px;
	// }
	.jcarousel-skin-default .jcarousel-prev-horizontal {left: -30px;}
	.jcarousel-skin-default .jcarousel-next-horizontal {right: -30px;}
	.jcarousel-skin-default .jcarousel-item {width: 160px; height: 160px;}
	.jcarousel-skin-default .jcarousel-container-horizontal, .jcarousel-skin-default .jcarousel-clip-horizontal {width: 340px; height: 162px;}
	ul#share {
		font-size: 100%;
	}
	#bottom-banner-content-wrapper {
		background: none;
	}
	#bottom-banner-content-wrapper div.block-content {
		background-color: #ed1c24;
		margin-left: -25px;
		padding-left: 25px;
		margin-top: -15px;
		padding-top: 15px;
	}
}

/* Landscape */
@media only screen
and (min-device-width: 375px)
and (max-device-width: 667px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: landscape) {
	.box {
		width: 28%;
		margin-left: 5px !important;
		margin-right: 5px !important;
	}
	.contentbox .row {
		clear: none;
	}
	#weta { margin-top: 10px; margin-bottom: 10px;}
	.jcarousel-skin-default .jcarousel-prev-horizontal {left: -30px;}
	.jcarousel-skin-default .jcarousel-next-horizontal {right: -30px;}
	.jcarousel-skin-default .jcarousel-item {width: 185px; height: 185px;}
	.jcarousel-skin-default .jcarousel-container-horizontal, .jcarousel-skin-default .jcarousel-clip-horizontal {width: 590px; height: 187px;}
}